<template>
  <b-navbar type="light" toggleable="md">
    <b-navbar-brand>
      <b-nav-item href="/" >
        <ANPLogo class="ccfr-logo" />
      </b-nav-item>
    </b-navbar-brand>
    <b-navbar-toggle target="navbar-toggle-collapse">
      <template #default="{ expanded }">
        <b-icon-chevron-bar-up v-if="expanded" />
        <b-icon-chevron-bar-down v-else />
      </template>
    </b-navbar-toggle>
    <b-collapse is-nav id="navbar-toggle-collapse">
      <b-navbar-nav>
      <nav-item
          :href="`${factorInvestingUrl}`"
          text="Factor Investing"
          :disabled="
            !this.user.permissions.includes(ALL_PRODUCTS.factorInvesting)
          "
        >
          <chart-bar-stacked
            :fillColor="ICONS_FILL_COLOR"
            class="item-icon"
          />
        </nav-item>
        <nav-item
          :href="`${baseUrl}/optimization-fe/marketSentiment`"
          text="Market Sentiment"
          :disabled="!this.user.permissions.includes(ALL_PRODUCTS.marketSentiment)"
        >
          <sum-icon
            :fillColor="ICONS_FILL_COLOR"
            class="item-icon"
          />
        </nav-item>
        <nav-item 
          to="/stockPickingLab"
          text="StockPicking Lab"  
          :disabled="!this.user.permissions.includes(ALL_PRODUCTS.stockPickingLab)"
        >
          <chart-box-icon :fillColor="ICONS_FILL_COLOR" />
        </nav-item>
        <nav-item
          to="/patternLab"
          text="Pattern Lab"
          :disabled="!this.user.permissions.includes(ALL_PRODUCTS.patternLab)"
        >
          <chart-multiline-icon
            :fillColor="ICONS_FILL_COLOR"
            class="item-icon"
          />
        </nav-item>
        <nav-item
          :href="`${baseUrl}/optimization-fe/portfolioManager`"
          text="Portfolio Manager"
          :disabled="!this.user.permissions.includes(ALL_PRODUCTS.portfolioOptimization)"
        >
          <portfolio-manager-icon
            :fillColor="ICONS_FILL_COLOR"
            class="item-icon"
          />
        </nav-item>
        <nav-item
          to="/taxes"
          text="Taxes"
          :disabled="
            !this.user.permissions.includes(ALL_PRODUCTS.tax)
          "
        >
          <cash-multiple
            :fillColor="ICONS_FILL_COLOR"
            class="item-icon"
          />
        </nav-item>
      </b-navbar-nav>
    </b-collapse>
    <b-navbar-nav class="ml-auto">
      <b-dropdown id="user-dropdown" variant="clear" v-if="user" right>
        <template #button-content>
          <acc-icon :fillColor="ICONS_FILL_COLOR" />
          <span>{{
            user.first_name && user.last_name
              ? `${user.first_name} ${user.last_name}`
              : user.email
          }}</span>
        </template>
        <b-dropdown-item to="/profile">{{
          $t("mainNav.profile")
        }}</b-dropdown-item>
        <b-dropdown-item @click="handleLogout" data-cy="logout">{{
          $t("mainNav.logout")
        }}</b-dropdown-item>
      </b-dropdown>
      <nav-item v-else text="Log in" to="/login">
        <acc-icon :fillColor="ICONS_FILL_COLOR" />
      </nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { ALL_PRODUCTS, ICONS_FILL_COLOR } from "../../utils/constants";
import { BIconChevronBarDown, BIconChevronBarUp } from "bootstrap-vue";
import { getBaseURL, getFactorInvestingURL } from "../../utils/helper";
import { mapActions, mapGetters } from "vuex";
import ANPLogo from "../../assets/img/ANP_logo_col.svg";
import AccIcon from "vue-material-design-icons/AccountCircle.vue";
import CashMultiple from "vue-material-design-icons/CashMultiple.vue";
import ChartBarStacked from "vue-material-design-icons/ChartBarStacked.vue";
import ChartBoxIcon from "vue-material-design-icons/ChartBoxPlusOutline.vue";
import ChartMultilineIcon from "vue-material-design-icons/ChartMultiline.vue";
import NavItem from "./NavItem.vue";
import PortfolioManagerIcon from "vue-material-design-icons/ChartTree.vue";
import SumIcon from "vue-material-design-icons/Sigma.vue";

export default {
  name: "Main-navigation",
  components: {
    AccIcon,
    BIconChevronBarDown,
    BIconChevronBarUp,
    CashMultiple,
    ChartBoxIcon,
    ChartMultilineIcon,
    ChartBarStacked,
    SumIcon,
    ANPLogo,
    NavItem,
    PortfolioManagerIcon,
  },
  data() {
    return {
      ALL_PRODUCTS,
      ICONS_FILL_COLOR,
    };
  },
  methods: {
    ...mapActions(["logOut"]),
    handleLogout() {
      this.logOut();
    },
    getBaseURL,
    getFactorInvestingURL
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
    baseUrl() {
      return this.getBaseURL(false, true);
    },
    factorInvestingUrl() {
      return this.getFactorInvestingURL();
    }
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid #bdbdbd;
  background: #fff;
  height: toRem(52);
  padding-left: toRem(15);
  padding-right: toRem(15);
  position: fixed;
  width: 100%;
  z-index: 11;
  box-shadow: (1px 1px 4px rgba(0, 0, 0, 0.25));

  .nav-item {
    &.disabled {
      .item-icon {
        opacity: 0.6;
      }
    }
  }
  .navbar-brand {
    width: toRem(219);
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .ccfr-logo {
    height: auto;
    width: auto;
    max-width: 90%;
  }
  #navbar-toggle-collapse {
    background-color: white;
  }
}
@media only screen and (max-width: 800px) {
  .navbar {
    min-height: toRem(52);
    height: auto;
  }
}
.nav-item {
  list-style: none;
}
</style>