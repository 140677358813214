<template>
  <b-nav-item
    v-bind="$attrs"
    @click="() => this.$emit('click')"
    :href="$attrs.href"
    :to="$attrs.to"
    :disabled="disabled"
  >
    <slot />
    <span class="link-text">{{ text }}</span>
    <lock-icon
      v-if="disabled"
      fillColor="#3687ff"
      class="lock-icon"
      :size="20"
    />
  </b-nav-item>
</template>

<script>
import LockIcon from "vue-material-design-icons/LockCheckOutline.vue";
export default {
  name: "NavItem",
  components: { LockIcon },
  props: {
    text: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav,
.navbar-nav .nav-link {
  span {
    color: $textBlack;
  }
  font-size: toRem(12);
  display: flex;
  align-items: center;
  .link-text {
    margin-left: toRem(6);
  }
}
.nav-link {
  &.router-link-active {
    background-color: rgba(54, 135, 255, 0.15);
    border-radius: toRem(7);
  }
}

.disabled {
  background: rgba(241, 241, 241, 0.6);
  margin-left: 0.5rem;
  border-radius: toRem(7);
  position: relative;
  .menu-icon {
    opacity: 0.6;
  }
  .lock-icon {
    opacity: 1;
    position: absolute;
    right: -10px;
    //top: 0px;
    bottom: 0;
  }
  .nav-link {
    .link-text {
      color: $primaryColor;
      opacity: 0.6;
    }
  }
}
</style>